import React from 'react'
import { IProductSearch } from '@core/api/Products/types'
import MinimizedProductCard from '@core/components/shared/MinimizedProductCard/MinimizedProductCard'

interface OwnProps {
  product: IProductSearch
}

const MinimizedProductCarouselItem: React.FC<OwnProps> = ({ product }) => (
  <MinimizedProductCard product={product} />
)

export default MinimizedProductCarouselItem
