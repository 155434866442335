import React from 'react'
import ProductCard, {
  ProductCardProps,
} from '@original/components/shared/ProductCard'
import getSku from '@core/utils/models/getSku'
import ProductDetailLink from '@core/components/ProductCollection/ProductDetailLink'
import style from './index.module.less'
import { LYSTypography } from '@core/components/Primitives'

const MinimizedProductCard: React.FC<ProductCardProps> = ({ product }) => {
  return (
    <ProductDetailLink
      product={product}
      slug={product.mainVariant.slug}
      id={product.mainVariant.id}
    >
      <ProductCard
        customClassName={style.minimizedCard}
        product={product}
        slots={{
          actionElements: () => null,
          description: () => (
            <LYSTypography.Text type="secondary">
              {getSku(product, product.mainVariant)}
            </LYSTypography.Text>
          ),
        }}
      />
    </ProductDetailLink>
  )
}

export default MinimizedProductCard
