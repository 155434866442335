import React from 'react'
import cn from 'classnames'
import {
  ButtonBack,
  ButtonBackProps,
  ButtonNext,
  ButtonNextProps,
  CarouselProvider,
  Slider,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { usePageContext } from '@core/utils/pages/PageContext'
import LYSIcon from '../../LYSIcon'
import styles from './index.module.less'
import { usePrismicSettings } from '@core/prismic/components/PageLayout/PrismicSettingsContext'
import DotGroup from '../DotGroup/DotGroup'

interface Props {
  children: React.ReactNode
}

const ProductButtonBack: React.FC<Omit<ButtonBackProps, 'children'>> = (
  props
) => {
  return (
    <ButtonBack
      {...props}
      className={cn(
        'ant-btn ant-btn-circle ant-btn-icon-only',
        styles.button,
        styles.prev
      )}
    >
      <LYSIcon component={LeftOutlined} />
    </ButtonBack>
  )
}

const ProductButtonNext: React.FC<Omit<ButtonNextProps, 'children'>> = (
  props
) => {
  return (
    <ButtonNext
      {...props}
      className={cn(
        'ant-btn ant-btn-circle ant-btn-icon-only',
        styles.button,
        styles.next
      )}
    >
      <LYSIcon component={RightOutlined} />
    </ButtonNext>
  )
}

const ProductCarouselProvider: React.FC<Props> = ({ children }) => {
  const {
    props: { isMobile, isTablet },
  } = usePageContext()
  const isTouchDevice = isMobile || isTablet
  const { minimized } = usePrismicSettings()

  const getVisibleSlider = () =>
    minimized
      ? isMobile
        ? 1.5
        : isTablet
        ? 3.5
        : 4.5
      : isMobile
      ? 1.5
      : isTablet
      ? 3
      : 4
  return (
    <CarouselProvider
      visibleSlides={getVisibleSlider()}
      totalSlides={React.Children.count(children)}
      naturalSlideWidth={1}
      naturalSlideHeight={1}
      infinite={false}
      isIntrinsicHeight
      dragEnabled={isTouchDevice}
      className={styles.slideContainer}
    >
      {!isTouchDevice && <ProductButtonBack />}
      <Slider tabIndex={-1} className={styles.sliderWrap}>
        {children}
      </Slider>
      {!isTouchDevice && <ProductButtonNext />}
      {isMobile && <DotGroup className={styles.dotGroup} />}
    </CarouselProvider>
  )
}

export default ProductCarouselProvider
