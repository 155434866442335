import React from 'react'

interface PrismicSettingsContextValue {
  minimized?: boolean
}

const PrismicSettingsContext = React.createContext<PrismicSettingsContextValue>(
  {}
)

export const PrismicSettingsProvider = PrismicSettingsContext.Provider

export const usePrismicSettings = () => {
  const value = React.useContext(PrismicSettingsContext)
  if (!value) {
    return {}
  }
  return value
}
