import { Skeleton, Spin } from 'antd'
import ProductCardWrap from '../../shared/ProductCard/ProductCardWrap'
import style from './index.module.less'

const ProductSkeleton: React.FC = () => {
  return (
    <ProductCardWrap>
      <div className={style.imageHolder}>
        <Spin size="large" />
      </div>
      <Skeleton active={true} />
    </ProductCardWrap>
  )
}

export default ProductSkeleton
