import React, { useMemo } from 'react'
import Link from 'next/link'
import {
  getImageFromVariantOrPlaceholder,
  IProductSearch,
} from '@core/api/Products/types'
import { LYSSpacer, LYSTypography } from '@core/components/Primitives'
import { LYSNewBadge } from '@core/components/Primitives/LYSBadge/LYSBadge'
import ProductDetailLink from '@core/components/ProductCollection/ProductDetailLink'
import ProductPrice from '@core/components/shared/ProductPrice'
import ReducedProductShortInformation from '../ProductShortInformation/ReducedProductShortInformation'
import { StockInfoLocation } from '@core/components/shared/StockInformation/types'
import config from '@core/config/config'
import routes from '@core/config/routes'
import { useServices } from '@core/utils/ioc'
import getSku from '@core/utils/models/getSku'
import { ProductContextProvider } from '@core/utils/models/product/ProductContext'
import { RenderProp, Slot, template } from '@core/utils/templates'
import StockInformation from '../StockInformation'
import ProductActionElements from './ProductActionElements'
import ProductCardWrap from './ProductCardWrap'
import style from './index.module.less'
import usePriceRequests from '@core/hooks/priceRequest/usePriceRequests'
import { useTranslation } from '@core/i18n/i18n'

export interface ProductCardProps {
  product: IProductSearch
  customClassName?: string
}

export interface ProductCardSlots {
  image?: RenderProp<void>
  isNew?: RenderProp<void>
  brand?: RenderProp<void>
  descriptionHolder?: RenderProp<void>
  title?: RenderProp<void>
  description?: RenderProp<void>
  summary?: RenderProp<void>
  price?: RenderProp<void>
  stockInformation?: RenderProp<void>
  actionElements?: RenderProp<void>
}

const ProductCard = template<ProductCardProps, ProductCardSlots>(
  ({ product, slots, customClassName }) => {
    const { productContextService } = useServices()
    const { t } = useTranslation()
    const productContext = useMemo(
      () =>
        productContextService.getProductSearchContext(
          product,
          product.mainVariant
        ),
      [productContextService, product]
    )
    const image = getImageFromVariantOrPlaceholder(product.mainVariant)
    const brandRoute = product.brand
      ? routes.brandDetail(product.brand.slug, product.brand.id)
      : undefined

    const { isRequestable } = usePriceRequests(product)
    const brandImage = (
      <div className={style.brandImage}>
        <img
          src={product?.brand?.image}
          alt={product?.brand?.name ? product?.brand?.name : ''}
          loading="lazy"
          width={200}
          height={200}
        />
      </div>
    )

    return (
      <ProductContextProvider productContext={productContext}>
        <ProductCardWrap customClassName={customClassName}>
          <Slot render={slots?.image}>
            <ProductDetailLink
              product={product}
              slug={product.mainVariant.slug}
              id={product.mainVariant.id}
            >
              <div className={style.imageHolder}>
                <img
                  src={image.url}
                  className={style.image}
                  title={product.name}
                  alt={product.name}
                  loading="lazy"
                  width={200}
                  height={200}
                />
              </div>
            </ProductDetailLink>
          </Slot>

          <Slot render={slots?.isNew}>
            {product.isNew && config.features.newProductsFeature && (
              <LYSNewBadge />
            )}
          </Slot>

          <Slot render={slots?.brand}>
            {product.brand && product.brand.image && (
              <>
                {brandRoute ? (
                  <Link {...brandRoute}>{brandImage}</Link>
                ) : (
                  brandImage
                )}
              </>
            )}
          </Slot>
          <Slot render={slots?.descriptionHolder}>
            <div className={style.productDescription}>
              <Slot render={slots?.title}>
                <ProductDetailLink
                  product={product}
                  slug={product.mainVariant.slug}
                  id={product.mainVariant.id}
                >
                  <LYSTypography.Text
                    visualAppearance={'h4'}
                    className={style.productName}
                    data-testid={'productCard_productName'}
                  >
                    {product.name}
                  </LYSTypography.Text>
                </ProductDetailLink>
              </Slot>
              <Slot render={slots?.description}>
                <ReducedProductShortInformation
                  shortDescription={product.shortDescription}
                  variationName={product.variationName}
                  sku={getSku(product, product.mainVariant)}
                />
              </Slot>
              <Slot render={slots?.summary} />
            </div>
          </Slot>
          <LYSSpacer />
          <Slot render={slots?.price}>
            {product.mainVariant.packagingUnits[0].price && (
              <div className={style.priceHolder}>
                <ProductPrice
                  price={product.mainVariant.packagingUnits[0].price}
                  unitPrice={product.mainVariant.packagingUnits[0].unitPrice}
                  strikePrice={
                    product.mainVariant.packagingUnits[0].strikeThroughPrice
                      ?.price
                  }
                  showDiscountHint={true}
                />
              </div>
            )}
          </Slot>
          <Slot render={slots?.stockInformation}>
            {!productContext.rules.hideStockInformation && (
              <StockInformation
                stock={productContext.stock}
                location={StockInfoLocation.ProductCard}
              />
            )}
            {productContext.rules.displayNoPriceHint && !isRequestable && (
              <div className={style.hintContainer}>
                {t('product.hint.noPrice')}
              </div>
            )}
          </Slot>
          <Slot render={slots?.actionElements}>
            <ProductActionElements />
          </Slot>
        </ProductCardWrap>
      </ProductContextProvider>
    )
  }
)

export default ProductCard
