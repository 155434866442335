import React from 'react'
import { IProductSearch } from '@core/api/Products/types'
import ProductCard from '@core/components/shared/ProductCard'
import ProductActionElements from '@core/components/shared/ProductCard/ProductActionElements'

interface OwnProps {
  product: IProductSearch
}

const ProductCarouselItem: React.FC<OwnProps> = ({ product }) => {
  return (
    <ProductCard product={product}>
      <ProductActionElements />
    </ProductCard>
  )
}

export default ProductCarouselItem
