import { IProductSearch } from '@core/api/Products/types'
import ProductCarouselItem from '@core/components/ProductCollection/ProductCarousel/ProductCarouselItem'
import { usePrismicSettings } from '@core/prismic/components/PageLayout/PrismicSettingsContext'
import MinimizedProductCarouselItem from './MinimizedProductCarouselItem'

export type ProductCarouselItemRenderer = (
  product: IProductSearch,
  minimizedCard?: boolean
) => React.ReactElement

export const DefaultProductCarouselItemRenderer = (
  product: IProductSearch,
  minimizedCard?: boolean
) => {
  const { minimized } = usePrismicSettings()
  return minimized || minimizedCard ? (
    <MinimizedProductCarouselItem product={product} />
  ) : (
    <ProductCarouselItem product={product} />
  )
}
