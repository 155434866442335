import React from 'react'
import { DotGroup as PureDotGroup } from 'pure-react-carousel'
import styles from './index.module.less'
import cn from 'classnames'
type Props = {
  className?: string
}

const DotGroup: React.FC<Props> = ({ className }) => {
  return <PureDotGroup className={cn(styles.dotGroup, className)} />
}

export default DotGroup
