import React, { useEffect, useState } from 'react'
import { IProductSearch } from '@core/api/Products/types'
import { LYSContainer, LYSTypography } from '@core/components/Primitives'
import ProductCarousel from '@core/components/ProductCollection/ProductCarousel'
import useRecommendations from '@core/hooks/useRecommendations'
import { RecommendationTypes } from '@core/api/Recommendations/types'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'
import cn from 'classnames'

const DEFAULT_LIMIT = 10

interface Props {
  variantIds?: string[]
  recommendationType?: RecommendationTypes
  limit?: number
  hideTitle?: boolean
  customTitle?: string
  titleStyle?: string
}

const RecommendationSlider: React.FC<Props> = ({
  recommendationType,
  limit,
  hideTitle,
  customTitle,
  titleStyle,
  variantIds,
}) => {
  const { t } = useTranslation()
  const [products, setProducts] = useState<IProductSearch[]>([])
  const recommendationLimit = limit || DEFAULT_LIMIT

  const { recommendations, isLoading } = useRecommendations({
    recommendationType,
    variantIds,
  })

  const title = customTitle
    ? customTitle
    : recommendationType
    ? t(`recommendations.title.${recommendationType}`)
    : t('recommendations.title.all')

  useEffect(() => {
    if (recommendations && recommendations?.length > 0) {
      if (recommendations.length > recommendationLimit) {
        recommendations.length = recommendationLimit
      }
      setProducts(recommendations)
    }
  }, [recommendations, recommendationType, limit, recommendationLimit])

  return products.length > 0 ? (
    <LYSContainer>
      {!hideTitle && (
        <LYSTypography.Title
          level={2}
          data-testid="recommendation-product-carousel"
          visualAppearance={'h3'}
          className={cn(style.titleCenter, titleStyle)}
        >
          {title}
        </LYSTypography.Title>
      )}
      <ProductCarousel
        productItems={products}
        loading={isLoading}
        minimizedCard={true}
      />
    </LYSContainer>
  ) : null
}

export default RecommendationSlider
