import React from 'react'
import { TFunction } from 'next-i18next'
import { useTranslation } from '@core/i18n/i18n'
import style from './LYSBadge.module.less'

interface Props {
  label: TFunction
  color: string
}

const LYSBadge: React.FC<Props> = ({ label, color }) => {
  return (
    <div
      color={color}
      style={{ borderColor: `${color}`, color: `${color}` }}
      className={style.container}
    >
      {label}
    </div>
  )
}

export const LYSNewBadge: React.FC = () => {
  const { t } = useTranslation()
  return <LYSBadge color="green" label={t('product.new')} />
}

export default LYSBadge
