import * as React from 'react'
import { LYSTypography } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import { RenderProp, Slot, template } from '@core/utils/templates'
import style from '@core/components/shared/ProductCard/index.module.less'

interface OwnProps {
  shortDescription?: string
  variationName?: string
  variantName?: string
  sku?: string
  skuProvidedBySupplier?: string
}

interface ReducedProductShortInformationSlots {
  shortDescription?: RenderProp<void>
  variationName?: RenderProp<void>
  variantName?: RenderProp<void>
  sku?: RenderProp<void>
  skuProvidedBySupplier?: RenderProp<void>
}

const ReducedProductShortInformation = template<
  OwnProps,
  ReducedProductShortInformationSlots
>(
  ({
    shortDescription,
    variationName,
    variantName,
    sku,
    skuProvidedBySupplier,
    slots,
  }) => {
    const { t } = useTranslation()

    return (
      <>
        <Slot render={slots?.shortDescription}>
          {shortDescription && (
            <LYSTypography.Paragraph className={style.paragraph} ellipsis>
              {shortDescription}
            </LYSTypography.Paragraph>
          )}
        </Slot>

        <div className={style.variationNameContainer}>
          <Slot render={slots?.variationName}>
            {variationName && (
              <LYSTypography.Paragraph
                ellipsis
                className={style.paragraph}
                type="secondary"
              >
                {variationName}
              </LYSTypography.Paragraph>
            )}
          </Slot>

          <Slot render={slots?.sku}>
            {sku && (
              <LYSTypography.Paragraph
                className={style.paragraph}
                type="secondary"
                data-testid={'product-information-sku'}
              >
                {sku}
              </LYSTypography.Paragraph>
            )}
          </Slot>

          <Slot render={slots?.variantName}>
            {variantName && (
              <LYSTypography.Paragraph
                className={style.paragraph}
                type="secondary"
                data-testid={'product-information-variantName'}
              >
                {variantName}
              </LYSTypography.Paragraph>
            )}
          </Slot>
        </div>

        <Slot render={slots?.skuProvidedBySupplier}>
          {skuProvidedBySupplier && (
            <>
              <LYSTypography.Paragraph
                className={style.paragraph}
                type="secondary"
              >
                {t('product.productInformation.skuProvidedBySupplier')}:{' '}
                {skuProvidedBySupplier}
              </LYSTypography.Paragraph>
            </>
          )}
        </Slot>
      </>
    )
  }
)

export default ReducedProductShortInformation
