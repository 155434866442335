import React from 'react'
import { LYSCard } from '@core/components/Primitives'
import style from './index.module.less'
import cn from 'classnames'

interface Props {
  children: React.ReactNode
  customClassName?: string
}

const ProductCardWrap: React.FC<Props> = ({ children, customClassName }) => (
  <LYSCard
    size="small"
    className={cn(style.card, customClassName)}
    data-testid="product-card"
    bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    withBoxShadow={true}
  >
    {children}
  </LYSCard>
)

export default ProductCardWrap
