import * as React from 'react'
import { Slide } from 'pure-react-carousel'
import { IProductSearch } from '@core/api/Products/types'
import { LYSEmpty } from '@core/components/Primitives'
import ProductCarouselProvider from '@core/components/Primitives/LYSCarousel/ProductCarousel/ProductCarouselProvider'
import {
  DefaultProductCarouselItemRenderer,
  ProductCarouselItemRenderer,
} from '@core/components/ProductCollection/ProductCarousel/ProductCarouselItemRenderer'
import ProductSkeleton from '@core/components/ProductCollection/ProductCollectionItemSkeleton'
import config from '@core/config/config'
import { useTranslation } from '@core/i18n/i18n'
import styles from './index.module.less'

interface Props {
  productItems?: IProductSearch[]
  customItemRenderer?: ProductCarouselItemRenderer
  loading?: boolean
  minimizedCard?: boolean
}

const NoProductsState: React.FC = () => {
  const { t } = useTranslation()
  return (
    <LYSEmpty
      image={config.assets.noResultsImage}
      description={t('searchResult.notFound.product.titleText')}
    />
  )
}

const LoadingState: React.FC = () => {
  const productsLoadingState = [...Array(4)].map((_, index) => {
    return (
      <Slide index={index} key={index}>
        <div className={styles.productWrap} key={index}>
          <ProductSkeleton />
        </div>
      </Slide>
    )
  })
  return (
    <ProductCarouselProvider>{productsLoadingState}</ProductCarouselProvider>
  )
}

const ProductCarousel: React.FC<Props> = ({
  productItems,
  customItemRenderer,
  loading,
  minimizedCard,
}) => {
  const itemRenderer = customItemRenderer || DefaultProductCarouselItemRenderer

  const products = productItems?.map((product: IProductSearch, index) => {
    return (
      <Slide
        tabIndex={-1}
        className={styles.productWrap}
        index={index}
        key={index}
      >
        {itemRenderer!(product, minimizedCard)}
      </Slide>
    )
  })

  return loading || !productItems ? (
    <LoadingState />
  ) : productItems.length > 0 ? (
    <ProductCarouselProvider>{products}</ProductCarouselProvider>
  ) : (
    <NoProductsState />
  )
}

export default ProductCarousel
